@font-face {
    font-family: "Rogue";
    src: url(/public/fonts/Rogue.ttf);
}

@font-face {
    font-family: "Bebas Neue";
    src: url(/public/fonts/BebasNeue-Regular.ttf);
}

body {
    background-color: #131419;
    color: #ffffff;
    margin: 0;
    font-family: Poppins, sans-serif;
}

a {
    color: rgb(4, 187, 4);
    font-weight: bold;
}

h1,
h2,
h3 {
    font-family: "Rogue";
    margin-top: 20px;
}

h4,
h5,
h6,
p {
    font-family: Poppins, sans-serif;
}

p {
    font-size: 16px;
}

.mb-0 {
    margin-bottom: 0;
}

button:hover {
    background-color: #d1d1d1;
}

button:disabled {
    color: #7e7979;
}

button:disabled:hover {
    cursor: not-allowed;
    background-color: white;
}

button {
    padding: 12px 40px;
    background-color: #fff;
    font-family: "Bebas Neue", sans-serif;
    color: #000;
    font-size: 18px;
    line-height: 1.3;
    text-align: left;
    letter-spacing: 2px;
    border: 0;
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
}

button img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

button.select {
    background-color: transparent;
    color: #fff;
}

.button-group {
    display: flex;
}

input,
select {
    background-color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #000;
    font-size: 18px;
    text-align: left;
    letter-spacing: 2px;
    border: 0;
    padding: 12px;
    /* min-width: 200px; */
    border-radius: 10px;
}

label {
    margin: 10px 0;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    font-weight: bold;
    background-color: #7fd185;
    box-shadow: 0 0 0 2px #5d9760;
}

a:active,
a:hover,
button:active,
button:hover,
label:hover {
    outline: 0;
    cursor: pointer;
}

.container {
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    margin: 0 auto;
}

.content {
    max-width: 600px;
    text-align: center;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
}

.background-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    animation: scaleIn 5s;
}

.shim {
    background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.fadeIn {
    animation: fadeIn 1s;
}

.errorMessageContainer {
    margin-bottom: 1rem;
    position: fixed;
    top: 35px;
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8b0000;
}

.successMessageContainer {
    margin-bottom: 1rem;
    position: fixed;
    top: 35px;
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #013220;
}

.error {
    color: red;
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 0 10px;
}

.success {
    color: green;
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.m20 {
    margin: 20px;
}

.amount {
    margin-top: 30px;
}

@keyframes scaleIn {
    from {
        transform: scale3d(1.2, 1.2, 1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes fadeIn {
    from {
        transform: translate3d(0px, 60px, 0px);
        opacity: 0;
    }

    to {
        transform: translate3d(0px, 0px, 0px);
        opacity: 1;
    }
}

.heading {
    font-size: 80px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 5px;
}

.sub-heading {
    font-family: Rogue, sans-serif;
    color: hsla(0, 0%, 100%, 0.84);
    font-size: 1vw;
    font-weight: 100;
    letter-spacing: 6px;
    margin-bottom: 0;
    font-size: 22px;
}

.title {
    font-family: 'Poppins';
    font-size: 2rem;
    color: #fff;
}

.title.active {
    color: green;
}

.sub-title {
    font-family: 'Poppins';
    font-size: 1.2rem;
    color: #fff;
}

.loading-spinner {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
}

.loading-spinner img {
    max-width: 140px;
}

.kitty-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.kitty img {
    max-height: 200px;
    padding: 10px;
}

.kitty:hover {
    cursor: pointer;
}

.kitty.selected img {
    border: 4px solid white;
    padding: 6px;
}

.token-list-view {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.token-list-view-controls {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.token-list-view .token-list-view-controls .button-group {
    margin-top: 0;
}

.token-list-view .button-group {
    margin-top: 40px;
}

.token-list-items {
    display: flex;
    flex-wrap: wrap;
}

.staking-buttons button:first-child {
    margin-right: 20px;
}

.token-label-box {
    border-radius: 10px;
    max-width: 400px;
    margin: 0.5rem;
    border: 2px solid;
}

.token-label {
    font-family: "Bebas Neue", sans-serif;
    background-color: #1C1C25;
    display: flex;
    height: 250px;
    width: 160px;
    flex-direction: column;
    border-radius: 10px;
}

.token-label:hover {
    cursor: pointer;
}

.token-label img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%
}

.header {
    height: 80px;
    width: 100%;
    background-color: #1C1C24;
}

.header-container {
    margin: 0 auto;
    max-width: 1080px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.header-container div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-link {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: white;
    text-decoration: none;
}

.header-link svg {
    margin-right: 5px
}

.header-link:hover {
    color: lightgrey;
}

.routes {
    margin-top: 60px;
}

.clickable:hover {
    cursor: pointer;
    opacity: 0.7;
}

.header-link.account {
    display: none;
}

.header button {
    margin-top: 0;
}

.header .admin h4 {
    margin-right: 20px;
}

.flex-grid {
    display: flex;
}

.col {
    flex: 1;
}

.account-data {
    background-color: #1C1C24;
    margin: 1rem;
    padding: 0 3rem;
    border-radius: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.account-data .address {
    word-break: break-all;
    margin-left: 10px;
}

.history-item {
    background-color: #1C1C24;
    margin: 1rem;
    padding: 1rem 3rem;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.history-item img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
}

.history-item p {
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
}

.history-item-icons span {
    margin: 0 1rem;
}

.admin-boxes {
    display: flex;
    flex-wrap: wrap;
}

.admin-box {
    flex: 1 1 32%;
    background-color: #1C1C24;
    padding: 1rem 3rem;
    margin: 1rem;
    border-radius: 30px;
    min-height: 300px;
    position: relative;
}

.admin-box-row {
    display: flex;
    justify-content: space-between;
}

.admin-box-row p {
    margin-right: 20px;
}

.admin-box-row input {
    margin-top: 10px;
}

.admin-box-row button {
    margin-top: 40px;
}

.admin-box:hover .admin-box-icons {
    display: block;
}

.admin-box-icons {
    display: none;
    position: absolute;
    right: 40px;
    top: 40px;
}

.admin-box-icons span {
    margin-left: 20px;
}

.admin-box-icons .delete-icon {
    color: red;
}

.admin-box .delete button {
    margin-top: 0;
}

.admin-box .confirm {
    background-color: red;
    color: white;
}

.history-item .delete-icon {
    right: 40px;
    top: 40px;
    color: red;
}

.icon:hover {
    cursor: pointer;
    opacity: 0.7;
}

.admin-box:hover {
    cursor: pointer;
}

input[type="date"] {
    padding: 0 1rem;
    border-radius: 10px;
}

input[type="date"]:hover {
    cursor: pointer;
}

.login {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #1C1C24;
    padding: 3rem;
    max-width: 300px;
    text-align: center;
}

.discord {
    background-color: #5856D5;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.discord img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}

.title-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.title-with-button .loading-spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.form-groups {
    display: flex;
    justify-content: space-between;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-groups .form-group {
    width: 48%;
}

.box-modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.box-modal-footer button[type="submit"] {
    background-color: #7fd185;
    color: white;
}

.box-modal-footer button[type="submit"]:hover {
    opacity: 0.7;
}

.kitty-box-modal {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
}

@media screen and (max-width: 991px) {
    .heading {
        font-size: 84px;
    }

    .sub-heading {
        font-size: 20px;
    }

    .token-list-view-controls {
        flex-direction: column;
    }

    .token-list-view-controls>div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .heading {
        font-size: 36px;
    }

    .container {
        padding: 0rem;
    }

    button {
        padding: 12px 28px;
        min-width: 100px;
    }

    .header .name {
        display: none;
    }

    .header .clickable {
        display: none;
    }

    .header-link.account {
        display: flex;
    }

    .header .admin {
        display: none;
    }
}

@media screen and (max-width: 479px) {
    .heading {
        display: block;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        font-size: 24px;
        text-align: left;
    }

    .sub-heading {
        font-size: 18px;
    }

    .container {
        padding: 0rem;
    }

    button {
        padding: 12px 20px;
        min-width: 100px;
    }

    .flex-grid {
        display: block;
    }

    .history-item {
        padding: 0.5rem 2rem;
    }

    .history-item img {
        height: 60px;
        width: 60px;
    }

    .title-with-button {
        display: block;
        text-align: center;
    }

    .title-with-button button {
        margin-bottom: 1rem;
        display: initial;
    }
    .kitty-box-modal {
        display: block;
    }
}